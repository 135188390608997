/*
    ===========================
    /|\                     /|\
    /|\                     /|\
    /|\    Sales Section    /|\
    /|\                     /|\
    /|\                     /|\
    ===========================
*/


@use './_widgets/reset-sales';
@use './_widgets/widget-two';
@use './_widgets/widget-one';
@use './_widgets/widget-three';
@use './_widgets/widget-chart-one';
@use './_widgets/widget-chart-two';
@use './_widgets/widget-table-one';
@use './_widgets/widget-activity-four';
@use './_widgets/widget-account-invoice-one';
@use './_widgets/widget-wallet-one';
@use './_widgets/widget-table-two';
@use './_widgets/widget-table-three';